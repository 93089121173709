<template lang="pug">
  .archive-page-title
    ArticleCardCategoryIcon.archive-page-title__icon(
      v-if="icon && !isHealthPath"
      :icon="icon"
      colorHover="#ED5E42"
    )
    h1.archive-page-title__text(
      v-text="title"
    )
</template>

<script>
  import ArticleCardCategoryIcon from '@/components/lh-ui/ArticleCardCategoryIcon/ArticleCardCategoryIcon.vue';
  import { isHealthPath } from '~/utils/router';

  export default {
    name: 'ArchivePageTitle',
    components: {
      ArticleCardCategoryIcon,
    },
    props: {
      icon: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        required: true,
      },
    },
    computed: {
      isHealthPath() {
        return isHealthPath(this.$route.path);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .archive-page-title {
    display: flex;
    align-items: center;
    background: white;

    &__text {
      @include fontH2;
    }

    &__icon {
      margin-right: 8px;
    }
  }
</style>
